import React, { useEffect, useState, createRef } from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Col, Badge, Nav } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLParser from 'html-react-parser';
import $ from 'jquery';
import axios from "axios"
import _ from "lodash"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/Home/Banner/Banner.js";
import ContactInfocommunity from "../components/Home/ContactInfo/ContactInfocommunity";
import CommunityStatistics from "../components/Community/Statistics";
import CommunitySearchList from "../components/Community/CommunitySearchList";
import AdditionalInfo from "../components/Home/ContactInfo/AdditionalInfo";
import TilesBlock from "../components/Home/ContactInfo/TileBlock";
import ProjectRating from "../components/Home/ProjectRating/ProjectRating_Communities"
import NewProjectRating from "../components/Home/ProjectRating/ProjectRating_NewProjects"
import Reviews from "../components/Home/Reviews/Reviews"
import GoogleSingleMap from "../components/maps/google/community_map"
import CountSection from "../components/CountSection/CountSection"
import LatestBlogs from "../components/Home/LatestBlogs/LatestBlogs"

import '../scss/custom.scss';
import '../scss/community.scss';
import LoaderLogo from '../images/loader_logo_new.svg';

import { CommunityDetails, CommunityDistrict } from "../queries/common_use_query"
import { removeDataAttributes } from "../comQueryStructure.js";
import { graphql } from "gatsby";
import { google_review_url } from "../components/common/utils.js";

function Community_Details(props) {
  const data = removeDataAttributes(props?.data).glstrapi
  const district_data = data?.communityDistricts

  const find_more_ref = createRef();

  let path_name = (props.location.pathname).split('/');
  let get_url = path_name[path_name.length - 1] !== "" ? path_name[path_name.length - 1] : path_name[path_name.length - 2];


  const [details, setDetails] = useState('');

  // const {loading:loading, error:error, data:data} = CommunityDetails(get_url);
  // const {loading:district_loading, error:district_error, data:district_data} = CommunityDistrict(get_url);


  const [testimonials, setTestimonials] = useState([]);

  const findoutMore = () => {
    find_more_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      const reviewsData = removeDataAttributes(data)
      setTestimonials(reviewsData)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  useEffect(() => {
    let url = google_review_url
    getitems(url);
  }, []);

  useEffect(() => {
    data && !_.isEmpty(data?.communities) && setDetails(data.communities[0]);
  }, [data]);

  useEffect(() => {
    district_data && !_.isEmpty(district_data) && setDetails(district_data[0]);
  }, [district_data]);

  var LayoutName = details && details.__typename === "GLSTRAPI_CommunityDistrict" ? 'Buy_Landing_Page comm_dist_det' : 'Buy_Landing_Page community-detail-page';

  var location = details.Location ? ', ' + details.Location : '';
  // console.log("details", details);

  let processedImages = JSON.stringify({}), image_name = '';
  if (details && details.imagetransforms && details.imagetransforms.Banner_Image_Transforms) {
    processedImages = details.imagetransforms.Banner_Image_Transforms;

    image_name = "community.Banner_Image.details";
  } else {
    image_name = "community-district.Banner_Image.details";
  }

  useEffect(() => {
    $(document).ready(function () {
      $(".tab-switch ul li a").click(function (e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html, body').animate({
          scrollTop: $(aid).offset().top - ($(".header").outerHeight() + $(".tab-switch").outerHeight() + 50)
        }, 2000);
        $(this).parent().addClass('active').siblings().removeClass('active');
      });

      $(window).on('scroll', function () {
        if ($(".main-banner-embed")[0].getBoundingClientRect().bottom < 0) {
          $('.tab-switch').addClass('stick');
        } else {
          $('.tab-switch').removeClass('stick');
        }
      });
    });
  }, []);

  return (
    <Layout Layout={LayoutName} classNames={LayoutName} Select_Popular_Search={'Popular_Search_Dubai_Contact'}>
      {details && <SEO title={details?.community?.Name ? details.Meta_Title + ', ' + details?.community?.Name : details.Meta_Title} description={details.Meta_Description} />}

      <Helmet bodyAttributes={{ class: `homepage` }} />

      {details ? <>
        {details.Banner_Image && details.Banner_Image.url &&
          <Banner Banner_Title={details.Name + location} CTA_1_Label={''} CTA_2_Label={''} Banner_Image={details.Banner_Image} Content={details.Content} Layout={LayoutName} SearchText={details.Name} imagename={image_name} article_id={details.id} imagetransforms={details.ggfx_result} handleClick={findoutMore} template={"community"} />
        }

        <div ref={find_more_ref}></div>

        <Nav className="tab-switch secondary-nav">
          <Container>
            <Row>
              <ul className="d-flex">
                <li class="active">
                  <a href="#about-section">About {details.Name}</a>
                </li>
                {details.Statistics && details.Statistics?.CommunityName &&
                  <li className="statistics-tab">
                    <a href="#statistics-section">Statistics</a>
                  </li>
                }
                <li>
                  <a href="#properties-section">Properties</a>
                </li>
                <li>
                  <a href="#additional-information-section">Additional Information</a>
                </li>
                {details.Show_Insights && details.Show_Insights.Show_Insights && details.Show_Insights.Filter_by_Tag &&
                  <li>
                    <a href="#news-section">News</a>
                  </li>
                }
              </ul>
            </Row>
          </Container>
        </Nav>

        {/* ======= Intro Section ====== */}
        {details.Intro_Content && details.Intro_Content.length > 0 &&
          <ContactInfocommunity Name={details.Name} Title={details.Intro_Content[0].Intro_Title} Content={details.Intro_Content[0].Intro_Text} Office_Details={details.Intro_Content[0].Choose_Office && details.Intro_Content[0].Choose_Office} AccordionItems={details.Accordion_Content && details.Accordion_Content.Add_Accor_Item.length > 0 && details.Accordion_Content.Add_Accor_Item} Accordian_Title={details.Intro_Content[0].Intro_Accordian_Title} Accordian_List={details.Intro_Content[0].Intro_Accordian_List} Services_Title={details.Intro_Content[0].Intro_Services_Title} Services_List={details.Intro_Content[0].Intro_Services_List} Service_Text={details.Intro_Content[0].Service_Text} Service_Icon={details.Intro_Content[0].Service_Icon} Collection="Community" classNames="grey_bg" Tile_Blocks={details.Community_Districts} />
        }
        {details.Statistics && details.Statistics?.CommunityName &&
          <CommunityStatistics Name={details.Name} Location={details.Location} Statistics={details.Statistics} />

        }

        {/* ======= Featured Properties ====== */}
        {/* {details.Show_NewProject_Property && details.Show_NewProject_Property.Publish && <NewProjectRating Heading={details.Show_NewProject_Property.Title && details.Show_NewProject_Property.Title} CommunityTab={false} Area_Name={details.Name} /> } */}

        <CommunitySearchList Name={details.Name} />

        {details.__typename === "GLSTRAPI_CommunityDistrict" && details.Name &&
          // <CountSection Counter_Block={details.Show_Counter_Block.Counter_Block} Counter_Title={details.Show_Counter_Block.Title} Area_Name={details.Name} />
          <CountSection district={district_data[0].community.Name} Area_Name={details.Name.replace('Commercial', '').replace('Residential', '')} get_url={get_url} />
        }

        {/* ======= Tiles  Section ====== */}
        {/* details.Community_Districts && details.Community_Districts.length > 0 &&
                <TilesBlock Tile_Blocks={details.Community_Districts} Collection="Community" Name={details.Name} />
            */}

        {/* ======= Map Section ====== */}
        {/*
                details.Latitude && details.Longitude &&
                <GoogleSingleMap lat={parseFloat(details.Latitude)} lng={parseFloat(details.Longitude)} height={"650px"}/>
            */}


        {/* ======= Featured Properties ====== */}
        {/* details.Show_Property && details.Show_Property.Show_Property && <ProjectRating Heading={details.Show_Property.Title && details.Show_Property.Title} CommunityTab={false} Area_Name={details.Name} /> */}

        {/* ======= Google Reviews ====== */}
        {/* details.Show_Google_Reviews && details.Show_Google_Reviews.Show_Google_Review && <div className="d-md-flex flex-wrap"> <Reviews testimonials={testimonials} /> </div> */}

        <AdditionalInfo Name={details.Name} Title={details.Intro_Content[0].Intro_Title} Content={details.Intro_Content[0].Additional_Text} Office_Details={details.Intro_Content[0].Choose_Office && details.Intro_Content[0].Choose_Office} AccordionItems={details.Accordion_Content && details.Accordion_Content.Add_Accor_Item.length > 0 && details.Accordion_Content.Add_Accor_Item} Accordian_Title={details.Intro_Content[0].Intro_Accordian_Title} Accordian_List={details.Intro_Content[0].Intro_Accordian_List} Services_Title={details.Intro_Content[0].Intro_Services_Title} Services_List={details.Intro_Content[0].Intro_Services_List} Service_Text={details.Intro_Content[0].Service_Text} Service_Icon={details.Intro_Content[0].Service_Icon} Collection="Community" classNames="grey_bg" Tile_Blocks={details.Community_Districts} />

        {/*======= Latest Blogs Insights-Case Studies ====== */}
        {details.Show_Insights && details.Show_Insights.Show_Insights && details.Show_Insights.Filter_by_Tag &&
          <div className="d-md-flex flex-wrap">
            <LatestBlogs Filter_by_Tag={details.Show_Insights.Filter_by_Tag} isCommunity /> </div>
        }

      </> : <div className="main-banner no_lines">
        <div className="loader_div">
          {/* <img src={LoaderLogo} alt="Loader" title="Loader" /><br />Loading... */}
        </div>
      </div>
      }
    </Layout>
  )
}

export const data = graphql`
query CommunityDetails($slug: String) {
  glstrapi {
    communities(filters: {URL: {eq: $slug}}) {
      data {
        id
        __typename
        attributes {
          Name
          URL
          Location
          Latitude
          Longitude
          rent_button_label
          rent_button_link
          sale_button_label
          sale_button_link
          Content
          Meta_Title
          Meta_Description
          Banner_Image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          imagetransforms
          ggfx_results {
            data {
              attributes {
                src_cftle
                transforms 
              }
            }
          }
          Intro_Content {
            Intro_Title
            Intro_Content
            Intro_Text
            Additional_Text
            Choose_Office {
              data {
                attributes {
                  Name
                  Direct_Phone
                  Image {
                    data {
                      attributes {
                        url
                        alternativeText
                      }
                    }
                  }
                }
              }
            }
            Intro_Services_Title
            Intro_Services_List {
              Service_Text
              Service_Icon {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            Intro_Accordian_Title
            Intro_Accordian_List {
              Content
              Title
            }
          }
          Show_Property {
            Title
            Show_Property
            Stb_List {
              Stb_List_Title
            }
          }
          Show_NewProject_Property {
            Title
            Publish
          }
          Show_Google_Reviews {
            Show_Google_Review
          }
          Show_Insights {
            Filter_by_Tag
            Show_Insights
          }
          Statistics {
            CommunityName
            Price_Last_Month
            Price_Previous_Month
            Price_Previous_Quarter
            Price_Previous_Year
            Monthly_Change
            Quarterly_Change
            Yearly_Change
          }
          Community_Districts {
            data {
              id
              attributes {
                Name
                URL
                Tile_Image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                imagetransforms
                ggfx_results {
                  data {
                    attributes {
                      src_cftle
                      transforms 
                    }
                  }
                }
                
              }
            }
          }
          plr_srch_cmties {
            data {
              attributes{
                Module_Name
                ModuleType {
                  ... on GLSTRAPI_ComponentModContentBlock {
                    Title
                    Content
                    id
                  }
                }
              }
            }
            
          }
        }
      }
    }
    communityDistricts(filters: {URL: {eq: $slug}}) {
      data {
        id
        attributes {
          __typename
          Name
          URL
          Location
          Latitude
          Longitude
          Content
          Meta_Title
          Meta_Description
          community {
            data {
              attributes {
                Name
              }
            }
          }
          Banner_Image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          imagetransforms
          ggfx_results {
            data {
              attributes {
                src_cftle
                transforms 
              }
            }
          }
          Intro_Content {
            Intro_Title
            Intro_Content
            Intro_Text
            Choose_Office {
              data {
                attributes {
                  Name
                  Direct_Phone
                }
              }
            }
            Intro_Services_Title
            Intro_Services_List {
              Service_Text
              Service_Icon {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            Intro_Accordian_Title
            Intro_Accordian_List {
              Content
              Title
            }
          }
          Accordion_Content {
            Add_Accor_Item {
              Title
              Content
            }
          }
          Show_Property {
            Title
            Show_Property
          }
          Show_Google_Reviews {
            Show_Google_Review
          }
          Show_Counter_Block {
            Show_Counter_Block
            Counter_Block {
              CTA_Label
              CTA_Link
              Name
              id
              Count
            }
          }
          plr_srch_cmties {
            data {
              attributes {
                Module_Name
                ModuleType {
                  ... on GLSTRAPI_ComponentModContentBlock {
                    Title
                    Content
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    communityDistricts(filters:{URL:{eq: $slug}}){
      data {
        id
        attributes {
          Name
          URL
          Location
          Latitude
          Longitude
          Content
          Meta_Title
          Meta_Description
          community {
            data {
              attributes {
                Name
              }
            }
          }
          Banner_Image{
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          imagetransforms
          ggfx_results {
            data {
              attributes {
                src_cftle
                transforms 
              }
            }
          }
          Intro_Content{
              Intro_Title
              Intro_Content
              Intro_Text
              Choose_Office {
                data {
                  attributes {
                    Name
                    Direct_Phone
                  }
                }
                  
              }
              Intro_Services_Title
              Intro_Services_List {
                Service_Text
                Service_Icon {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
              Intro_Accordian_Title
              Intro_Accordian_List {
                Content
                Title
              }
          }
          Accordion_Content{
              Add_Accor_Item{
                Title
                Content
              }
          }
          Show_Property{
              Title
              Show_Property
          }
          Show_Google_Reviews{
              Show_Google_Review
          }
          Show_Counter_Block {
              Show_Counter_Block
              Counter_Block {
                CTA_Label
                CTA_Link
                Name
                id
                Count
              }
          }
          plr_srch_cmties {
            data {
              attributes {
                Module_Name
                ModuleType {
                  ... on GLSTRAPI_ComponentModContentBlock {
                    Title
                    Content
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default Community_Details