import { Link } from "@StarberryUtils";
import React, {useEffect, useState } from "react"
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import $ from 'jquery';

// Style
import './ContactInfo.scss';

import Accordion from "react-bootstrap/Accordion"

const AdditionalInfo = (props) => {

    // console.log("Services_List", props.Accordian_List);

    const [activeId, setActiveId] = useState(props.activeId ? props.activeId : "0");

    function toggleActive(id) {
        if (activeId === id) {
            setActiveId(null)
        } else {
            setActiveId(id)
        }
    }

    let ListArray;
    ListArray = props.Accordian_List;

    return (
        <div id="additional-information-section" className={`contact-info additional-info ${props.classNames}`}>
            <Container>
                <Row>
                    <Col xl={12}>
                         <div className="animated">
                         <h2>More about {props.Name}, Dubai</h2>
                            <p className="desc full-text">{HTMLReactParser(props.Content)}</p>

                            <div className="desc">
                                {
                                    ListArray &&  ListArray.length > 0 &&
                                     <div className="animated">
                                        <Accordion defaultActiveKey={activeId}>
                                            {
                                                ListArray.length > 0 && ListArray.map((item, index) => {
                                                    return (
                                                        <Card>
                                                            <Accordion.Toggle onClick={() => toggleActive(index.toString())} className={`accordion-header`} eventKey={index.toString()}>
                                                                {item.Title}
                                                                <i className={activeId === index.toString() ? "icon-minus-accordion" : "icon-plus-accordion"}></i>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={index.toString()}>
                                                                <div className="accordion-card">
                                                                    <p>{item.Content && HTMLReactParser(item.Content)}</p>
                                                                </div>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    )
                                                })
                                            }
                                        </Accordion>
                                     </div>
                                }
                            </div>
                         </div>
                    </Col>
                </Row>
            </Container>
        </div>)
}

export default AdditionalInfo